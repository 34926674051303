<template>
  <div class="container">
   首页
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang='scss'  scoped>
@import "@/scss/index/index.scss";
</style>
